import React, { useEffect, useLayoutEffect, useState } from "react";
import { isBrowser, localTokenHandler } from "../common/Utils";
import { refreshToken } from "../services/OTPServices";
import { navigate } from "gatsby";
import LoginProvider from "../components/Login/context/LoginProvider";
import RainbowLaneStyled from "../components/Login/styles/RainbowLaneStyled";
import LoginStyled, { LoaderContainerStyled, LogoContainerStyled } from "../components/Login/styles/LoginStyled";
import { LogoSVG } from "@womcorp/wom-miwom-ui-kit/dist/@theme/assets/svg/miscellaneous";
import { FlexLayout } from "@womcorp/wom-miwom-ui-kit/dist/components/Layout";
import { LoaderBlock } from "@womcorp/wom-miwom-ui-kit/dist/components/Loader";
import { RedirectionPageType } from "../components/Login/@types";

const PortalRedirect: RedirectionPageType = ({ pageContext: { initialUniverse }}) => {

  const [token, ] = useState(localTokenHandler.get());

  useLayoutEffect(() => {
    if (isBrowser()) {
      const [url, tkn] = window.location.href.split('#');
      window.localStorage.setItem('path', url.split('?path=')[1] || '');
      if(tkn){
        localTokenHandler.set(tkn);
        window.location.replace(url);
      }
    }
  }, []);

  useEffect(() => {
    if (isBrowser() && token) {
      refreshToken(token)
        .then((res) => {
          if(res.status && res.status >= 400) throw new Error(res.data) 
          const path = window.localStorage.getItem('path');
          if(path){
            window.localStorage.removeItem('path');
            window.open(path, '_self');
          } else {
            navigate('/');
          }
        })
        .catch(() => {
          navigate('/');
        })
    }
  }, []);

  return (
    <LoginProvider initialUniverse={initialUniverse}>
      <RainbowLaneStyled />
      <LoginStyled>
        <LogoContainerStyled>
          <LogoSVG />
        </LogoContainerStyled>
        <FlexLayout padding={'32px 0 0 0'}>
          <LoaderContainerStyled>
            <LoaderBlock />
          </LoaderContainerStyled>
        </FlexLayout>
      </LoginStyled>
    </LoginProvider>
  );

};

export default PortalRedirect;